<template>
	<div>
		<member-bonus-filters
			:username-filter.sync="usernameFilter"
			:product-filter.sync="productFilter"
			:product-options="productOptions"
			:bonus-code-filter.sync="bonusCodeFilter"
			:fromdate-filter.sync="fromdateFilter"
			:todate-filter.sync="todateFilter"
			:is-void-filter.sync="isVoidFilter"
			:is-void-options="isVoidOptions"
		/>
		<b-card
			no-body
		>
			<b-table
				ref="refMemberBonusListTable"
				class="position-relative table-white-space mb-0 max-height-table"
				:items="fetchMemberBonusCode"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(username)="data">
					<b-link
						v-if="data.item.user"
						:to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						{{ data.item.user.username }}
					</b-link>
				</template>

				<template #cell(bonusCode)="data">
					{{ data.item.bonus_code ? data.item.bonus_code.code : ''}}
				</template>

				<template #cell(amount)="data">
					{{ data.item.amount ? numberFormat(data.item.amount) : '' }}
				</template>

				<template #cell(status)="data">
					<b-link
						:class="`text-${resolveStatus(data.item.status).variant}`"
					>
						{{ resolveStatus(data.item.status).label }}
					</b-link>
				</template>

				<template #cell(createdBy)="data">
					{{ data.item.made_by }}
				</template>

				<template #cell(product)="data">
					{{ data.item.product ? data.item.product.wallet_name : '' }}
				</template>

				<template #cell(voidBy)="data">
					{{ data.item.void_by }}
				</template>

				<template #cell(voidAt)="data">
					{{ data.item.void_time }}
				</template>

				<template #cell(createdAt)="data">
					{{ formatDateTime(data.item.created_at ? data.item.created_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(updatedAt)="data">
					{{ formatDateTime(data.item.updated_at ? data.item.updated_at : '').replace('.000000Z', '') }}
				</template>

				<template #cell(action)="data">
					<b-link
						v-if="data.item.status == 1"
						class="font-weight-bold text-nowrap"
						@click="showUpdateMemberBonusCodeModal(data.item)"
					>
						Update
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalMemberBonus"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<update-status-member-bonus-code-modal
			:member-bonus-code-detail.sync="memberBonusCodeDetail"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { formatDateTime, numberFormat } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import MemberBonusFilters from './MemberBonusFilters.vue'
import store from '@/store'
import bonusStoreModule from '@/views/bonus/bonusStoreModule'

export default {
	components: {
		MemberBonusFilters,
		UpdateStatusMemberBonusCodeModal: () => import('./UpdateStatusMemberBonusCodeModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
			memberBonusCodeDetail: null,
		}
	},
	methods: {
		showUpdateMemberBonusCodeModal(datas) {
			this.memberBonusCodeDetail = datas
			this.$bvModal.show('modal-update-status-member-code')
		},
	},
	setup() {
		if (!store.hasModule('bonus')) store.registerModule('bonus', bonusStoreModule)
		const perPage = ref(25)
		const totalMemberBonus = ref(0)
		const currentPage = ref(1)
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)
		const usernameFilter = ref(null)
		const bonusCodeFilter = ref(null)
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)
		const productFilter = ref(null)
		const productOptions = ref([])
		const isVoidFilter = ref(null)
		const isVoidOptions = ref([])
		const statusFilter = ref(null)
		const refMemberBonusListTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'username', label: 'Username' },
			{ key: 'bonusCode', label: 'Bonus Code' },
			{ key: 'amount', label: 'Transfer Amount' },
			{ key: 'rolloverAmount', label: 'Rollover Amount' },
			{ key: 'isMeetRollover', label: 'Is Meet Rollover' },
			{ key: 'isVoid', label: 'Is Void' },
			{ key: 'voidBy', label: 'Void By' },
			{ key: 'voidAt', label: 'Void At' },
			'product',
			{ key: 'createdBy', label: 'Created By' },
			{ key: 'createdAt', label: 'Created At' },
			{ key: 'updatedAt', label: 'Updated At' },
			{ key: 'action', label: 'Action' },
		]

		const refetchData = () => {
			refMemberBonusListTable.value.refresh()
		}

		watch([currentPage, usernameFilter, bonusCodeFilter, fromdateFilter, todateFilter], () => {
			refetchData()
		})

		const fetchMemberBonusCode = (ctx, callback) => {
			store.dispatch('bonus/fetchMemberBonusCode', {
				username: usernameFilter.value,
				bonus_code: bonusCodeFilter.value,
				page: currentPage.value,
				status: statusFilter.value,
				fromDate: fromdateFilter.value,
				toDate: todateFilter.value,
			})
				.then(response => {
					callback(response.data.data)
					totalMemberBonus.value = response.data.count
					perPage.value = currentPage.value == 1 ? response.data.data.length : 25
				})
		}

		const dataMeta = computed(() => {
			const localItemsCount = refMemberBonusListTable.value ? refMemberBonusListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalMemberBonus.value,
			}
		})

		const statusOptions = [
			{ label: 'Active', value: 2 },
			{ label: 'inActive', value: 1 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Active', variant: 'primary' }
			if (status == 2) return { label: 'inActive', variant: 'danger' }
			return { label: 'Pending', variant: 'secondary' }
		}
		return {
			fetchMemberBonusCode,
			tableColumns,
			perPage,
			currentPage,
			totalMemberBonus,
			dataMeta,
			sortBy,
			isSortDirDesc,
			refMemberBonusListTable,

			refetchData,

			usernameFilter,
			productFilter,
			bonusCodeFilter,
			productOptions,
			isVoidFilter,
			isVoidOptions,
			fromdateFilter,
			todateFilter,
			statusFilter,

			resolveStatus,
			statusOptions,

			formatDateTime,
			numberFormat,
		}
	},
}
</script>
<style>
	.b-table-sticky-column {
		right: 0!important;
	}
	td.b-table-sticky-column {
		background-color: inherit;
	}
</style>